import { FC } from "react";
import { classNames, pivotStyles } from "../../styles/MergeStyleSets";
import { Pivot, PivotItem } from "@fluentui/react";
import { t } from "i18next";
import MeetingAttachment from "./MeetingAttachment";
import { IEvent, IMessage } from "../../model/model";
import MeetingAttendanceReport from "./MeetingAttendanceReport";
import TranscriptWebVTTDisplay from "./TranscriptWebVTTDisplay";

interface IMeetingMetadataProps {
    event: IEvent;
    message: IMessage;
}

const MeetingMetadata: FC<IMeetingMetadataProps> = (props) => {
    const renderTranscript = (transcript: string) => {
        if (transcript) {
            return <TranscriptWebVTTDisplay data={transcript} />;
        }
    };
    return <div className={classNames.meetingItemContainer} style={{ height: '375px' }}>
        <Pivot style={{ padding: '5px' }} linkFormat="tabs" styles={pivotStyles}>
            {
                props.event.attachments?.length > 0 &&
                <PivotItem headerText={t('Files')} itemIcon="Document">
                    <MeetingAttachment event={props.event} />
                </PivotItem>
            }
            <PivotItem headerText={t('Attendees')} itemIcon="People">
                <MeetingAttendanceReport event={props.event} message={props.message} />
            </PivotItem>
            {
                props.message.eventDetail?.meetingTranscriptAndAttendanceReport?.transcript?.content &&
                <PivotItem headerText={t('Transcript')} itemIcon="TextDocument">
                    {
                        renderTranscript(props.message.eventDetail?.meetingTranscriptAndAttendanceReport?.transcript.content)
                    }
                </PivotItem>
            }
        </Pivot>
        <div className={classNames.meetingItemContainerContent}></div>
    </div>;
};

export default MeetingMetadata;