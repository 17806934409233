import { FC } from "react";
import { classNames } from "../../styles/MergeStyleSets";
import { Features } from "../../config/Features";
import { t } from "i18next";
import { ICommonProps } from "../ICommonProps";

const MeetingChat: FC<ICommonProps> = (props) => {
    if (Features.EnableMeetingArchiving) {
        return <blockquote className={classNames.errorBlockQuote}>
            <h3>{t('Meeting Chat Archiving is not supported in this version of 360°')}</h3>
        </blockquote>;
    }
};

export default MeetingChat;