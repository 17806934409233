import { BrowserRouter, Route, Routes, useSearchParams } from 'react-router-dom';
import ApiErrorHandler from './context/ApiErrorHandler';
import ConfigScreen from './components/config/ConfigScreen';
import ErrorBoundary from './components/error/ErrorBoundary';
import Support from './components/Support';
import Health from './components/Health';
import Redirect from './components/Redirect';
import { TeamsContextProvider } from './context/TeamsContext';
import { DataProvider } from './providers/DataProvider';
import { P360ContextProvider } from './context/P360Context';
import { FilesContextProvider } from './context/FilesContext';
import ArchiveScreenLayout from './components/fileList/ArchiveScreenLayout';
import ConsentOK from './components/ConsentOK';
import ConsentError from './components/ConsentError';
import ChatArchiving from './components/chatArchiving/ChatArchiving';
import { MessagesContextProvider } from './context/MessagesContext';
import MeetingArchiving from './components/meetingArchiving/MeetingArchiving';

const termsAndPrivacyUrl = "https://www.tietoevry.com/siteassets/files/privacy-notice/privacy-notice-terms-use-public-360-microsoft-teams-integration.pdf";
const dataProvider = new DataProvider();


function App(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                {["/", "/archive"].map((path) => {
                    return (
                        <Route path={path} element={<RootWrapper />}
                            key={path}
                        />
                    );
                })}
                <Route path='/chatarchive' element={<ChatArchive />} />
                <Route path='/meetingarchive' element={<MeetingArchive />} />
                <Route path='/config' element={<TeamsContextProvider dataProvider={dataProvider}><ConfigScreen dataProvider={dataProvider} /></TeamsContextProvider>} />
                <Route path='/support' element={<Support />} />
                <Route path='/health' element={<Health />} />
                {["/privacy", "/termsofuse"].map((path) => {
                    return (
                        <Route path={path} element={<Redirect url={termsAndPrivacyUrl} />}
                            key={path}
                        />
                    );
                })}
            </Routes>
        </BrowserRouter>
    );
}

function MeetingArchive(): JSX.Element {
    const [searchParams] = useSearchParams();
    if (searchParams.get("error")) { return <ConsentError searchParams={searchParams} />; }
    if (searchParams.get("admin_consent")?.toLowerCase() === "true") { return <ConsentOK />; }

    return <ApiErrorHandler>
        <ErrorBoundary>
            <TeamsContextProvider dataProvider={dataProvider}>
                <P360ContextProvider dataProvider={dataProvider}>
                    <MessagesContextProvider dataProvider={dataProvider}>
                        <MeetingArchiving dataProvider={dataProvider} />
                    </MessagesContextProvider>
                </P360ContextProvider>
            </TeamsContextProvider>
        </ErrorBoundary>
    </ApiErrorHandler>;
}

function ChatArchive(): JSX.Element {
    const [searchParams] = useSearchParams();
    if (searchParams.get("error")) { return <ConsentError searchParams={searchParams} />; }
    if (searchParams.get("admin_consent")?.toLowerCase() === "true") { return <ConsentOK />; }

    return <ApiErrorHandler>
        <ErrorBoundary>
            <TeamsContextProvider dataProvider={dataProvider}>
                <P360ContextProvider dataProvider={dataProvider}>
                    <MessagesContextProvider dataProvider={dataProvider}>
                        <ChatArchiving dataProvider={dataProvider} />
                    </MessagesContextProvider>
                </P360ContextProvider>
            </TeamsContextProvider>
        </ErrorBoundary>
    </ApiErrorHandler>;
}

function RootWrapper(): JSX.Element {
    const [searchParams] = useSearchParams();
    if (searchParams.get("error")) { return <ConsentError searchParams={searchParams} />; }
    if (searchParams.get("admin_consent")?.toLowerCase() === "true") { return <ConsentOK />; }

    return <ApiErrorHandler>
        <ErrorBoundary>
            <TeamsContextProvider dataProvider={dataProvider}>
                <P360ContextProvider dataProvider={dataProvider}>
                    <FilesContextProvider dataProvider={dataProvider}>
                        <ArchiveScreenLayout dataProvider={dataProvider} />
                    </FilesContextProvider>
                </P360ContextProvider>
            </TeamsContextProvider>
        </ErrorBoundary>
    </ApiErrorHandler>;
}

export default App;
