import { FC, FormEvent, useCallback, useEffect, useState } from "react";
import { EnumEntityCreationMode, IFileListItem, ISharepointDocumentItem, ISyncConfig } from "../../model/model";
import { ChoiceGroup, IChoiceGroupOption, Stack, Toggle } from "@fluentui/react";
import { viewSubHeaderAlignmentStyles, viewSubHeaderContentAlignmentStyles } from "../../styles/MergeStyleSets";
import { t } from "i18next";
import { EnumArchiveAction } from "../fileList/CommandBarContainer";
import { useFiles } from "../../context/FilesContext";

interface ICaseViewAutoArchiveSectionProps {
    setSyncConfig: (config: ISyncConfig) => void;
    selectedFiles?: ISharepointDocumentItem[],
    archiveAction?: EnumArchiveAction
}

const getDefaultAutoArchivingEnabled = (selectedFiles?: ISharepointDocumentItem[], archiveAction?: EnumArchiveAction, channelRootFolder?: IFileListItem) => {
    const defaultEnabled = false;
    if (archiveAction === EnumArchiveAction.FolderConnection && selectedFiles && selectedFiles.length === 1) {
        return selectedFiles[0].syncConfig?.SyncEnabled ?? defaultEnabled;
    }

    if (archiveAction === EnumArchiveAction.ChannelArchiving && channelRootFolder?.syncConfig?.SyncEnabled === true) {
        return true;
    }

    return defaultEnabled;
};

const getDefaultAaEntityCreationMode = (selectedFiles?: ISharepointDocumentItem[], archiveAction?: EnumArchiveAction, channelRootFolder?: IFileListItem) => {
    const defaultMode = EnumEntityCreationMode.CreateDocumentPerFolder;
    if (archiveAction === EnumArchiveAction.FolderConnection && selectedFiles && selectedFiles.length === 1) {
        return selectedFiles[0].syncConfig?.EntityCreationMode ?? defaultMode;
    }

    if (archiveAction === EnumArchiveAction.ChannelArchiving && channelRootFolder?.syncConfig?.EntityCreationMode) {
        return channelRootFolder.syncConfig.EntityCreationMode;
    }

    return defaultMode;
};

const CaseViewAutoArchiveSection: FC<ICaseViewAutoArchiveSectionProps> = ({ selectedFiles, setSyncConfig, archiveAction }) => {
    const { channelRootFolder } = useFiles();
    const [autoArchivingEnabled, setAutoArchivingEnabled] = useState<boolean>(() => getDefaultAutoArchivingEnabled(selectedFiles, archiveAction, channelRootFolder));
    const [aaEntityCreationMode, setAaEntityCreationMode] = useState<EnumEntityCreationMode>(() => getDefaultAaEntityCreationMode(selectedFiles, archiveAction, channelRootFolder));

    const aaSyncOptions: IChoiceGroupOption[] = [
        { key: EnumEntityCreationMode[EnumEntityCreationMode.CreateSingleDocument], text: t('Create one single document in 360 for all files') },
        { key: EnumEntityCreationMode[EnumEntityCreationMode.CreateDocumentPerFile], text: t('Create separate documents in 360 for each file') },
        { key: EnumEntityCreationMode[EnumEntityCreationMode.CreateDocumentPerFolder], text: t('Create separate documents in 360 for each folder') }
    ];

    const onChangeAaEnabled = useCallback((ev?: FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
        setAutoArchivingEnabled(checked ?? false);
    }, []);

    const onChangeSyncOption = useCallback((ev?: FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        if (option) {
            setAaEntityCreationMode(EnumEntityCreationMode[option.key as keyof typeof EnumEntityCreationMode]);
        }
    }, []);

    useEffect(() => {
        const newSyncConfig: ISyncConfig = {
            SyncEnabled: autoArchivingEnabled,
            EntityCreationMode: aaEntityCreationMode,
            InheritFromParent: false
        };
        setSyncConfig(newSyncConfig);
    }, [autoArchivingEnabled, aaEntityCreationMode, setSyncConfig]);

    const subHeader = archiveAction === EnumArchiveAction.ChannelArchiving
        ? t('Automatic channel synchronization')
        : t('Automatic folder synchronization');

    const subHeaderPostfix = archiveAction === EnumArchiveAction.FolderConnection && selectedFiles && selectedFiles.length > 1
        ? ` (${selectedFiles.length})`
        : null;

    return <Stack>
        <Stack.Item align="auto" styles={viewSubHeaderAlignmentStyles}>
            <span style={viewSubHeaderContentAlignmentStyles}>{subHeader}{subHeaderPostfix}</span>
        </Stack.Item>
        <Stack.Item>
            <Toggle label={t('Enable automatic synchronization')} inlineLabel checked={autoArchivingEnabled} onChange={onChangeAaEnabled} />
        </Stack.Item>
        <Stack.Item>
            <ChoiceGroup
                selectedKey={EnumEntityCreationMode[aaEntityCreationMode]}
                options={aaSyncOptions}
                onChange={onChangeSyncOption}
                required={autoArchivingEnabled}
                disabled={!autoArchivingEnabled}
            />
        </Stack.Item>
    </Stack>;
};

export default CaseViewAutoArchiveSection;