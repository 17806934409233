import { Checkbox, IconButton } from '@fluentui/react';
import { FC, useCallback, useEffect, useState } from 'react';

interface IAccordianProps {
    id: string;
    title: string;
    items: { [key: string]: boolean };
    width?: number;
    onSelectionChange(id: string, title: string, selectedItems: { [key: string]: boolean }): void;
}

const Accordion: FC<IAccordianProps> = (props) => {
    const { id, title, items, width, onSelectionChange } = props;
    const [isOpen, setIsOpen] = useState(false);

    const [checkedItems, setCheckedItems] = useState(items);
    const [isAllChecked, setIsAllChecked] = useState(false);
    const [intermediate, setIntermediate] = useState(false);

    const onCollapse = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        const checkedValues = Object.values(checkedItems);
        const allChecked = checkedValues.every(Boolean);
        const noneChecked = checkedValues.every((value) => !value);

        // Update isAllChecked state and the indeterminate property
        setIsAllChecked(allChecked);
        setIntermediate(!allChecked && !noneChecked);
    }, [checkedItems, items]);

    const onHeaderCheckboxChange = useCallback(() => {
        const newCheckState = !isAllChecked;
        setIsAllChecked(newCheckState);

        const updatedCheckedItems = Object.fromEntries(Object.keys(items).map(item => [item, newCheckState]));
        setCheckedItems(updatedCheckedItems);

        onSelectionChange(id, title, updatedCheckedItems);
    }, [id, isAllChecked, items, onSelectionChange, title]);

    const onCheckboxChange = useCallback((item: string, checked?: boolean) => {
        const updatedCheckedItems = {
            ...checkedItems,
            [item]: checked as boolean,
        };
        setCheckedItems(updatedCheckedItems);

        const allChecked = Object.keys(items).every((item) => updatedCheckedItems[item]);
        setIsAllChecked(allChecked);

        onSelectionChange(id, title, updatedCheckedItems);
    }, [checkedItems, id, items, onSelectionChange, title]);

    const onRenderHeaderLabel = () => {
        return <strong style={{ paddingLeft: '10px' }}>{title}</strong>;
    };

    return <div style={{ display: 'flex', flexDirection: 'column', width: `${width ?? 300}px` }}>
        <div style={{
            display: 'flex', alignItems: 'center', justifyContent: 'space-between',
            height: '35px', borderBottom: '1px solid #ccc'
        }}>
            <div style={{ display: 'flex', columnGap: '10px' }}>
                <Checkbox checked={isAllChecked} indeterminate={intermediate} onChange={onHeaderCheckboxChange} onRenderLabel={onRenderHeaderLabel} />
            </div>
            <div>{isOpen ? <IconButton iconProps={{ iconName: "ChevronUp" }} onClick={onCollapse} data-testid="collapseAccordian" /> :
                <IconButton iconProps={{ iconName: "ChevronDown" }} onClick={onCollapse} data-testid="openAccordian" />}</div>
        </div>
        {isOpen ? (
            Object.keys(items).map((item, index) => (
                <div key={`${id}_${index}`} style={{ padding: '10px' }}>
                    <Checkbox checked={checkedItems[item] || false} label={item} onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                        onCheckboxChange(item, checked);
                    }} />
                </div>
            ))
        ) : null}
    </div>;
};

export default Accordion;
