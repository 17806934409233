import { FC, RefObject, useCallback } from "react";
import { DefaultButton, PrimaryButton } from "@fluentui/react";
import { t } from "i18next";
import { useBoolean } from "@fluentui/react-hooks";
import { useMessages } from "../../context/MessagesContext";
import { ArchiveMeetingRequestParameter, EnumCase, EnumSearchCriteria, IArchiveMeetingResult, ITeamRequest } from "../../model/model";
import { MessagesActionType } from "../../context/MessagesReducer";
import { IMessageCaseViewRef } from "../chatArchiving/panel/MessageCaseView";
import { IMessageNewDocumentViewRef } from "../chatArchiving/panel/MessageNewDocumentView";
import { useTeams } from "../../context/TeamsContext";
import { MapTeamsContextToITeamRequest } from "../../helpers/Mapper";
import { ICommonProps } from "../ICommonProps";

interface IRenderPanelFooterProps extends ICommonProps {
    closePanel: () => void;
    messageCaseViewRef: RefObject<IMessageCaseViewRef>;
    messageNewDocumentViewRef: RefObject<IMessageNewDocumentViewRef>;
    onSaveComplete?(response: IArchiveMeetingResult): void;
}

export enum PanelStep {
    MeetingSelectionView = 0,
    CaseView = 1,
    DocumentView = 2
}

export const RenderPanelFooter: FC<IRenderPanelFooterProps> = (props) => {
    const { closePanel, messageCaseViewRef, messageNewDocumentViewRef, dataProvider, onSaveComplete } = props;
    const { messageState, dispatch } = useMessages();
    const { teamsState } = useTeams();
    const [isSavingInProgress, { setTrue: savingInProgress, setFalse: savingFinished }] = useBoolean(false);

    const isNewCaseSelected = useCallback(() => {
        return messageState.caseDocumentData.selectionNewOrExisting === EnumCase.NewCase;
    }, [messageState.caseDocumentData.selectionNewOrExisting]);

    const isDocumentSearchCriteriaSelected = useCallback(() => {
        return messageState.searchCriteria === EnumSearchCriteria.Document;
    }, [messageState.searchCriteria]);

    const onClosePanel = useCallback(() => {
        savingFinished();
        closePanel();
    }, [closePanel, savingFinished]);

    const defaultButtonText = useCallback(() => {
        if (messageState.stepWizard === PanelStep.MeetingSelectionView) {
            return t("Cancel");
        }
        else if (messageState.stepWizard === PanelStep.CaseView || messageState.stepWizard === PanelStep.DocumentView) {
            return t("Go back");
        }
    }, [messageState.stepWizard]);

    const primaryButtonText = useCallback(() => {
        if (messageState.stepWizard === PanelStep.MeetingSelectionView) {
            return t("Continue");
        }
        if (messageState.stepWizard === PanelStep.CaseView) {
            if (isNewCaseSelected()) {
                return t("Continue");
            }
            return isDocumentSearchCriteriaSelected() ? t("Save to 360") : t("Continue");
        }
        if (messageState.stepWizard === PanelStep.DocumentView) {
            return t("Save to 360");
        }
    }, [isDocumentSearchCriteriaSelected, isNewCaseSelected, messageState.stepWizard]);

    const onDefaultClick = useCallback(() => {
        if (defaultButtonText() === t("Cancel")) {
            onClosePanel();
        }
        else if (defaultButtonText() === t("Go back")) {
            let stepNumber = messageState.stepWizard;
            if (stepNumber > 0) {
                stepNumber = stepNumber - 1;
            }
            dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: stepNumber } });
        }
    }, [defaultButtonText, dispatch, messageState.stepWizard, onClosePanel]);

    const onSaveUsingCaseAndDocument = useCallback(async () => {
        const token = await teamsState.getAccessToken();
        const request: ArchiveMeetingRequestParameter = {
            accessToken: token,
            teamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest,
            caseDocumentRequest: messageState.caseDocumentData,
            meetingResponse: messageState.meetingState,
            selectedMeetings: messageState.selectedMeetings
        };
        const response = await dataProvider?.P360.archiveMeetings(request);
        if (response?.data) {
            onSaveComplete && onSaveComplete(response.data);
        }
    }, [dataProvider?.P360, messageState.caseDocumentData, messageState.meetingState, messageState.selectedMeetings, onSaveComplete, teamsState]);

    const onSaveExistingDocument = useCallback(async () => {
        const token = await teamsState.getAccessToken();
        const request: ArchiveMeetingRequestParameter = {
            accessToken: token,
            teamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest,
            caseDocumentRequest: messageState.caseDocumentData,
            meetingResponse: messageState.meetingState,
            selectedMeetings: messageState.selectedMeetings
        };
        const response = await dataProvider?.P360.archiveMeetingOnExistingDocument(request);
        if (response?.data) {
            onSaveComplete && onSaveComplete(response.data);
        }
    }, [dataProvider?.P360, messageState, onSaveComplete, teamsState]);
    
    const onSaveClick = useCallback(async () => {
        savingInProgress();
        if (messageState.stepWizard === PanelStep.DocumentView) {
            await onSaveUsingCaseAndDocument();
        }
        else if (messageState.stepWizard === PanelStep.CaseView) {
            if (isDocumentSearchCriteriaSelected())
                await onSaveExistingDocument();
        }
        closePanel();
    }, [closePanel, isDocumentSearchCriteriaSelected, messageState.stepWizard, onSaveExistingDocument, onSaveUsingCaseAndDocument, savingInProgress]);

    const onPrimaryClick = useCallback(() => {
        if (messageState.stepWizard === PanelStep.CaseView) {
            if (!messageCaseViewRef.current?.validate())
                return;
        }
        else if (messageState.stepWizard === PanelStep.DocumentView) {
            if (!messageNewDocumentViewRef.current?.validate())
                return;
        }

        if (primaryButtonText() === t("Save to 360")) {
            onSaveClick();
        }
        else if (primaryButtonText() === t("Continue")) {
            switch (messageState.stepWizard) {
                case PanelStep.MeetingSelectionView:
                    dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: PanelStep.CaseView } });
                    break;
                case PanelStep.CaseView:
                    dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: PanelStep.DocumentView } });
                    break;
            }
        }
    }, [dispatch, messageState.stepWizard, onSaveClick, primaryButtonText, messageCaseViewRef, messageNewDocumentViewRef]);

    return <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px', justifyContent: 'flex-end' }}>
        <DefaultButton text={defaultButtonText()} onClick={onDefaultClick} />
        <PrimaryButton disabled={isSavingInProgress} text={primaryButtonText()} onClick={onPrimaryClick} />
    </div>;
};