import { IObjectWithKey } from "@fluentui/react";

export interface IProgressItem {
    loading: boolean,
    text?: string
}
export interface ISharepointFileResponse_Folder {
    ChildCount: number;
}

export interface ISharepointFileResponse_File {
    Name: string;
    MimeType: string;
}

export interface ISharepointFileResponse_User {
    Email: string;
    Id: string;
    DisplayName: string;
}

export interface SharepointFileResponse_CreatedBy {
    User?: ISharepointFileResponse_User;
}

export interface IDriveItem {
    driveId?: string,
    itemId?: string,
    isFolder?: boolean
}

export interface ISharepointFileRequest {
    driveItems: IDriveItem[]
}

// SI.Biz.Core.Integration.TeamsArchive.Response.SharepointFileResponse
export interface ISharepointFileResponse {
    IsDocument: boolean;
    Folder: ISharepointFileResponse_Folder;
    File: ISharepointFileResponse_File;
    Id: string;
    DriveId: string;
    WebUrl: string;
    ModifiedDateTime: Date;
    CreatedBy: SharepointFileResponse_CreatedBy;
    LastModifiedBy: SharepointFileResponse_CreatedBy;
    Case: Case,
    Document: Document,
    Package?: Package,
    ArchiveCount: number,
    TotalCount: number,
    IsReadonly: boolean,
    IsCheckedout: boolean,
    IsNotSupported: boolean,
    IsConnectedTo360: boolean,
    IsSuccessful: boolean,
    ErrorMessage: string,
    IsSyncFailed?: boolean,
    Recno?: number,
    ContentUrl?: string,
    SyncConfig?: ISyncConfig
}

// SI.Biz.Core.Integration.TeamsArchive.Response.Case
export interface Case {
    Recno?: number,
    CaseNumber?: string,
    Title?: string,
    LinkTo360?: string,
    IsClosed?: boolean
}

// SI.Biz.Core.Integration.TeamsArchive.Response.Document
export interface Document {
    Recno: number,
    DocumentNumber: string,
    Title: string,
    DocumentStatus: string,
    /**
     * @deprecated This property is obsolete.
     */
    ArchiveStatus: string,
    LinkTo360: string,
    IsDocumentReadOnly?: boolean
}
export interface Package {
    Type?: string
}
export interface ILinkTo360 {
    Document: string;
    Case: string;
}

export interface IAccessGroupResponse {
    Recno: number;
    Code: string;
}

export interface ISharepointDocumentItemState {
    items: ISharepointDocumentItem[],
    key: number
}

export interface ISharepointDocumentItem extends IObjectWithKey {
    fileId?: string,
    fileDriveId?: string,
    isFolder?: boolean,
    fileName?: string,
    iconName?: string,
    fileType?: string,
    modified?: string,
    modifiedBy?: string,
    caseNumber?: string,
    documentNumber?: string,
    caseConnection?: string,
    documentConnection?: string,
    caseTitle?: string,
    documentTitle?: string,
    documentStatus?: string,
    /**
     * @deprecated This property is obsolete.
     */
    archiveStatus?: string,
    packageType?: string,
    archiveCount?: number,
    totalCount?: number,
    isReadonly?: boolean,
    IsCheckedout?: boolean,
    isNotSupported?: boolean,
    isConnectedTo360?: boolean,
    isSyncFailed?: boolean,
    fileRecno?: number,
    spFileDownloadUrl?: string
    syncConfig?: ISyncConfig
}

export interface IFileListItem extends ISharepointDocumentItem {
    parentId?: string,
    archivingStatusChecked?: boolean
}

// SI.Biz.Core.Integration.TeamsArchive.Request.CaseDocumentRequest
export interface ICaseDocumentRequest {
    caseNumber?: string,
    caseTitle?: string,
    accessGroup?: string,
    processRecno?: number,
    processName?: string,
    selectionNewOrExisting?: EnumCase,
    createSingleOrMultipleDocument?: EnumDocument
    documentTitle?: string,
    documentNumber?: string,
    recordTypeRecno?: number,
    publicityClassRecno?: number,
    personalDataRecno?: number,
    actionTypeRecno?: number,
    documentPublicityClassRecno?: number,
    syncConfig?: ISyncConfig,
}

export interface ICaseDocumentResponse {
    CasesResult: IGetCasesResult,
    DocumentsResult: IGetDocumentResult
}

export interface IGetCasesResult {
    Cases: ICaseResult[],
    TotalPageCount: number,
    TotalCount: number
}

export interface ICaseResult {
    Recno: number,
    CaseNumber: string,
    Title: string,
    Category: ICategory,
    ResponsiblePerson: IResponsiblePerson,
    Documents: ICaseDocumentResult[],
    Contacts: ICaseContactResult[],
    URL: string,
    Status: string,
    CaseTypeCode: string,
    ResponsibleEnterpriseName: string,
    AccessCodeCode: string,
    AccessGroup: string,
    LastChangedDate: Date
}

export interface ICaseContactResult {
    ContactName: string,
    Role: string,
    Recno: number
}

export interface ICaseDocumentResult {
    Recno: number,
    DocumentNumber: string,
    DocumentTitle: string
}

export interface IGetDocumentResult {
    Documents: IDocumentResult[],
    TotalPageCount: number,
    TotalCount: number
}

export interface IDocumentResult {
    Recno: number,
    DocumentNumber: string,
    Title: string,
    Category: ICategory,
    ResponsiblePerson: IResponsiblePerson,
    Files: IDocumentFileResult[],
    URL: string,
    StatusDescription: string,
    ResponsibleEnterpriseName: string,
    AccessCodeCode: string,
    AccessCodeDescription: string,
    Paragraph: string,
    AccessGroup: string,
    Case: ICaseResult,
    CustomFields: IAdditionalFieldParameter[]
}

export interface IAdditionalFieldParameter {
    Name: string,
    Value: string
}

export interface IDocumentFileResult extends IObjectWithKey {
    Recno?: number,
    Title?: string,
    Format?: string,
    IconName?: string,
    Document?: string, //connected document of the file
    ModifiedBy?: string,
    LastChangedDate?: Date,
    URL?: string,
    Note?: string
}

export interface IResponsiblePerson {
    Name: string
}

export interface ICategory {
    Code: string
}

export interface ICaseResponse {
    Recno: number,
    Title: string,
    CaseNumber: string,
    ClassCode: IClassCode
}

export interface IProgressState {
    loading: boolean,
    text?: string
}

export enum EnumCase {
    NewCase = '1',
    ExistingCase = '2'
}

export enum EnumDocument {
    CreateSingleDocument = '1',
    CreateMultipleDocument = '2'
}

export enum EnumViewName {
    ChangeCaseView = 1,
    CaseView = 2,
    ExistingCaseView = 3
}

export enum EnumUIConfiguration {
    Normal = '',
    ProcessView = 'Processview',
    Sahke2 = 'Sahke2'
}

export enum EnumNewCaseFieldsError {
    Required,
    AccessCode,
    PersonalData,
    PublicityClass
}

export enum EnumDocumentFieldsError {
    Required,
    AccessCode,
    DocumentCategory,
    PersonalData,
    PublicityClass
}

export enum EnumSearchCriteria {
    Case = 'Case',
    Document = 'Document',
    All = 'All'
}

export enum EnumHoverCard {
    Case = '1',
    Document = '2'
}

export interface ITeamServiceRequest {
    accessToken?: string,
    driveId?: string,
    fileId?: string,
    fileName?: string,
    fileFormat?: string,
    isFolder?: boolean,
    teamRequest?: ITeamRequest,
    nextLinkUrl?: string,
    caseDocumentRequest?: ICaseDocumentRequest,
    sharepointFileRequest?: ISharepointFileRequest,
    fileRecnos?: number[]
}
export interface ITeamRequest {
    teamId: string,
    teamName: string,
    teamSiteDomain: string,
    teamSitePath: string,
    teamSiteUrl: string,
    channel: ITeamChannel,
    chat: ITeamChat
}
export interface ITeamChannel {
    channelId: string,
    channelName: string,
    channelType: string,
    channelRelativeUrl: string
}
export interface ITeamChat {
    id: string
}
export interface IBackendCapabilities {
    Configuration: ITeamsConfiguration,
    Information: IInformation,
    Capabilities?: string[]
}
export interface IInformation {
    Version: string
}
export interface ITeamsConfiguration {
    UIConfiguration: EnumUIConfiguration
}
export interface ISearchCase {
    searchCriteria: string | null,
    includeClosedCases: boolean
}
export interface ISearchEntity {
    searchCriteria: string | null,
    startRow: number,
    maxRow: number
}
export interface IClassCodeResponse {
    Label: string,
    ClassCodes: IClassCode[]
}
export interface IClassCode {
    Recno: number,
    Code: string,
    Description: string,
    CodeAndDescription: string,
    IsDocumentCategorySupportable: boolean,
    IsAccessCodeSupportable: boolean,
    PublicityClass: IPublicityClassResponse,
    PersonalDataRecno: number
}
export interface IPublicityClassResponse {
    Recno: number,
    Code: string,
    IsSupportable: boolean
}
export interface IGetDocumentRequest {
    caseNumber?: string,
    documentNumber?: string,
    includeFiles: boolean
}
export interface IGetHistoryParameterRequest {
    entityName: string,
    startRow: number,
    maxRow: number,
    includeClosedCases: boolean
}
export interface IGetSearchParameterRequest {
    searchText: string | null,
    startRow: number,
    maxRow: number
}
export interface IEntityHistoryItem {
    Recno: string,
    EntityNumber: string,
    Title: string
}

export interface ArchiveFileOnExistingDocumentRequestParameter {
    AccessToken: string,
    TeamRequest: ITeamRequest,
    DriveId: string,
    FileId: string,
    FileName: string,
    DocumentNumber: string
}

export interface ArchiveChatsOnExistingDocumentsRequestParameter {
    AccessToken: string,
    TeamRequest: ITeamRequest,
    MessageResponse: IMessageResponse,
    DocumentNumber: string,
    IncludeAttachments: boolean
}

export interface ArchiveChatsRequestParameter {
    AccessToken: string,
    TeamRequest: ITeamRequest,
    MessageResponse: IMessageResponse,
    caseDocumentRequest: ICaseDocumentRequest,
    IncludeAttachments: boolean
}

export interface FetchUserProfilePhotoRequestParameter {
    AccessToken: string,
    TeamRequest: ITeamRequest,
    Members: IConversationMember[]
}

export interface AppPermissionsRequestParameter {
    AccessToken: string;
    TeamRequest: ITeamRequest;
    AppId?: string;
}

export interface SyncFileWithTeamsRequestParameter {
    accessToken: string,
    fileRecno: number,
    enumGraphApiAuthMode?: EnumGraphApiAuthMode
}

export interface GetMeetingTranscriptRequestParameter {
    AccessToken: string;
    TeamRequest: ITeamRequest;
    MeetingId: string;
    CallIds: string[];
}

export interface ArchiveMeetingRequestParameter {
    accessToken: string;
    teamRequest: ITeamRequest;
    caseDocumentRequest: ICaseDocumentRequest;
    meetingResponse: IMeetingResponse;
    selectedMeetings: ISelectedMeetingData[];
}

export enum EnumGraphApiAuthMode {
    IdToken = 1,
    AccessToken = 2
}

export interface IMessageResponse {
    NextLink: string,
    Messages: IMessage[],
    Title: string,
    Members: IConversationMember[]
}

export interface IMessage extends IObjectWithKey {
    Id?: string,
    createdDateTime?: Date,
    LastModifiedDateTime?: Date,
    From?: IFrom,
    Body?: IBody,
    Attachments?: IAttachment[],
    Reactions?: IReaction[],
    eventDetail?: IEventDetail
}

export interface IConversationMember {
    Id: string,
    DisplayName: string,
    UserId: string,
    Email: string,
    Photo: string
}

export interface IFrom {
    User: IConversationMember
}

export interface IBody {
    ContentType: string,
    Content: string
}

export interface IDocumentResponse {
    DocumentNumber: string,
    Error: string,
    TotalAttachmentCount: number,
    TotalAttachmentArchivedCount: number
}

export interface IAttachment {
    Id: string,
    ContentType: string,
    ContentUrl: string,
    Name: string
}

export interface IReaction {
    ReactionType: string
}
export interface IMessageItem extends IObjectWithKey {
    id?: string,
    lastModifiedDateTime?: Date,
    fromUser?: string,
    fromUserId?: string,
    fromUserPhoto?: string,
    bodyContentType?: string,
    bodyContent?: string,
    attachments?: IMessageAttachment[],
    reactions?: IMessageReaction[]
}

export interface IMessageAttachment {
    id: string,
    name: string,
    contentType: string,
    contentUrl: string
}

export interface IMessageReaction {
    reactionType: string
}

export interface IMeetingResponse {
    event: IEvent,
    meeting: IMeeting,
    messages: IMessage[],
}

export interface IEvent {
    id: string,
    createdDateTime: Date,
    lastModifiedDateTime: Date,
    bodyPreview: string,
    body: IBody,
    start: IEventDateTime,
    end: IEventDateTime,
    attendees: IParticipant[],
    organizer: IParticipant,
    attachments: IMeetingAttachment[]
}

export interface IMeeting {
    id: string,
    topic: string,
    createdDateTime: Date,
    lastUpdatedDateTime: Date,
    chatType: string,
    onlineMeetingInfo: IOnlineMeetingInfo
}

export interface IOnlineMeetingInfo {
    id: string,
    calendarEventId: string,
    joinWebUrl: string
}

export interface IEventDateTime {
    dateTime: Date,
    timeZone: string
}

export interface IParticipant {
    type: string,
    emailAddress: IEmailAddress
}

export interface IEmailAddress {
    name: string,
    address: string
}

export interface IMeetingAttachment {
    '@odata.nextLink': string,
    id: string,
    name: string,
    contentType: string
}

export interface IEventDetail extends IObjectWithKey {
    '@odata.type': string,
    callId: string,
    callRecordingDisplayName: string,
    callRecordingUrl: string,
    callRecordingDuration: string,
    callRecordingStatus: string,
    callTranscriptICalUid: string,
    callEventType: string,
    callDuration: string
    meetingTranscriptAndAttendanceReport: IMeetingTranscriptAndAttendanceReport
}

export interface IMeetingTranscriptAndAttendanceReport {
    callId: string,
    transcript: IMeetingTranscript,
    attendanceReport: IAttendanceReport
}

export interface IMeetingTranscript {
    id: string,
    callId: string,
    transcriptContentUrl: string,
    content: string
}

export interface IAttendanceReport {
    id: string,
    meetingStartDateTime: Date,
    meetingEndDateTime: Date,
    attendanceRecords: IAttendanceRecord[]
}

export interface IAttendanceRecord {
    id: string,
    emailAddress: string,
    identity: Identity
}

export interface Identity {
    Id: string,
    DisplayName: string
}

export interface ISelectedMeetingData {
    id: string;
    title: string;
    selectedItems: string[];
}

export interface IMessageAndSelectedMeeting {
    isAttendanceReportArchived: string;
    isTranscriptArchived: string;
    isRecordingArchived: string;
    allAttachmentsArchived: string;
}

export interface IArchiveMeetingResult {
    documentNumber: string;
    isMeetingDescriptionArchived: string;
    areMeetingChatsArchived: string;
    selectedMeetingResults: ISelectedMeetingData[]
}

// SI.Biz.Core.Integration.TeamsArchive.Model.EntityCreationMode
export enum EnumEntityCreationMode {
    None = 0,
    CreateSingleDocument = 1,
    CreateDocumentPerFolder = 2,
    CreateDocumentPerFile = 3
}

// SI.Biz.Core.Integration.TeamsArchive.Model.SyncConfig
export interface ISyncConfig {
    SyncEnabled: boolean,
    EntityCreationMode: EnumEntityCreationMode,
    InheritFromParent?: boolean,
}

// SI.Biz.Core.Integration.TeamsArchive.Model.SetChannelAutomaticArchivingRequestParameter
export interface SetChannelAutomaticArchivingRequestParameter {
    AccessToken: string,
    TeamRequest: ITeamRequest,
    CaseDocumentRequest: ICaseDocumentRequest
}

// SI.Biz.Core.Integration.TeamsArchive.Response.ChannelAutomaticArchivingInfo
export interface ChannelAutomaticArchivingInfo {
    DriveItem: IDriveItem,
    Case: Case,
    SyncConfig: ISyncConfig
}

export interface GetChannelAutomaticArchivingInfoRequestParameter {
    accessToken: string,
    teamRequest: ITeamRequest
}