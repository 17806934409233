import { FC, useCallback, useRef } from 'react';
import { DefaultButton, IPanelProps, IRenderFunction, Panel, PanelType, PrimaryButton } from '@fluentui/react';
import { t } from "i18next";
import { ICommonProps } from '../../ICommonProps';
import { IMessageCaseViewRef, MessageCaseView } from './MessageCaseView';
import { ArchiveChatsOnExistingDocumentsRequestParameter, ArchiveChatsRequestParameter, EnumCase, EnumSearchCriteria, IDocumentResponse, IMessageItem, ITeamRequest } from '../../../model/model';
import { mapMessageItemToMessageResponse, MapTeamsContextToITeamRequest } from '../../../helpers/Mapper';
import { useTeams } from '../../../context/TeamsContext';
import { useMessages } from '../../../context/MessagesContext';
import { useBoolean } from '@fluentui/react-hooks';
import { StepWizard } from '../StepWizard';
import { IMessageNewDocumentViewRef, MessageNewDocumentView } from './MessageNewDocumentView';
import { MessagesActionType } from '../../../context/MessagesReducer';
import { RenderPanelHeader } from '../../common/RenderPanelHeader';

interface IChatArchivePanelProps extends ICommonProps, IPanelProps {
    isOpen: boolean;
    selectedMessages?: IMessageItem[];
    includeAttachments: boolean;
    onSaveComplete?(response: IDocumentResponse): void;
    onSaveError?(error: Error): void;
}

const ChatArchivePanel: FC<IChatArchivePanelProps> = (props) => {
    const { isOpen, selectedMessages, includeAttachments, onSaveComplete, dataProvider, onDismiss } = props;
    const { teamsState } = useTeams();
    const { messageState, dispatch } = useMessages();
    const [isSavingInProgress, { setTrue: savingInProgress, setFalse: savingFinished }] = useBoolean(false);
    const messageCaseViewRef = useRef<IMessageCaseViewRef>(null);
    const messageNewDocumentViewRef = useRef<IMessageNewDocumentViewRef>(null);

    const closePanel = useCallback(() => {
        dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: 0 } });
        dispatch({ type: MessagesActionType.UpdateSearchCriteria, payload: { searchCriteria: EnumSearchCriteria.Case } });
        dispatch({
            type: MessagesActionType.ArchiveMessages, payload: {
                caseDocumentData: {
                    ...messageState.caseDocumentData,
                    caseNumber: "",
                    documentNumber: "",
                    documentTitle: "",
                    selectionNewOrExisting: EnumCase.ExistingCase
                }
            }
        });
        savingFinished();
        onDismiss && onDismiss();
    }, [dispatch, messageState.caseDocumentData, onDismiss, savingFinished]);

    const onSaveUsingCaseAndDocument = useCallback(async () => {
        const token = await teamsState.getAccessToken();
        const request: ArchiveChatsRequestParameter = {
            AccessToken: token,
            TeamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest,
            caseDocumentRequest: messageState.caseDocumentData,
            MessageResponse: mapMessageItemToMessageResponse(messageState, selectedMessages),
            IncludeAttachments: includeAttachments
        };
        const response = await dataProvider?.P360.archiveChatMessages(request);
        if (response?.data) {
            onSaveComplete && onSaveComplete(response.data);
        }
    }, [dataProvider?.P360, includeAttachments, messageState, onSaveComplete, selectedMessages, teamsState]);

    const onSaveExistingDocument = useCallback(async () => {
        const token = await teamsState.getAccessToken();
        const request: ArchiveChatsOnExistingDocumentsRequestParameter = {
            AccessToken: token,
            TeamRequest: MapTeamsContextToITeamRequest(teamsState.userContext) as ITeamRequest,
            DocumentNumber: { ...messageState.caseDocumentData }.documentNumber as string,
            MessageResponse: mapMessageItemToMessageResponse(messageState, selectedMessages),
            IncludeAttachments: includeAttachments
        };
        const response = await dataProvider?.P360.archiveChatMessagesOnExistingDocument(request);
        if (response?.data) {
            onSaveComplete && onSaveComplete(response.data);
        }
    }, [dataProvider?.P360, includeAttachments, messageState, onSaveComplete, selectedMessages, teamsState]);

    const isNewCaseSelected = useCallback(() => {
        return messageState.caseDocumentData.selectionNewOrExisting === EnumCase.NewCase;
    }, [messageState.caseDocumentData.selectionNewOrExisting]);

    const isDocumentSearchCriteriaSelected = useCallback(() => {
        return messageState.searchCriteria === EnumSearchCriteria.Document;
    }, [messageState.searchCriteria]);

    const onSaveClick = useCallback(async () => {
        savingInProgress();
        if (messageState.stepWizard === 1) {
            await onSaveUsingCaseAndDocument();
        }
        else if (messageState.stepWizard === 0) {
            if (isDocumentSearchCriteriaSelected())
                await onSaveExistingDocument();
        }
        closePanel();
    }, [closePanel, isDocumentSearchCriteriaSelected, messageState.stepWizard, onSaveExistingDocument, onSaveUsingCaseAndDocument, savingInProgress]);

    const primaryButtonText = useCallback(() => {
        if (messageState.stepWizard === 0) {
            if (isNewCaseSelected()) {
                return t("Continue");
            }
            return isDocumentSearchCriteriaSelected() ? t("Save to 360") : t("Continue");
        }
        if (messageState.stepWizard === 1) {
            return t("Save to 360");
        }

    }, [isDocumentSearchCriteriaSelected, isNewCaseSelected, messageState.stepWizard]);

    const defaultButtonText = useCallback(() => {
        if (messageState.stepWizard === 0) {
            return t("Cancel");
        }
        else if (messageState.stepWizard === 1) {
            return t("Go back");
        }
    }, [messageState.stepWizard]);

    const onPrimaryClick = useCallback(() => {
        if (messageState.stepWizard === 0) {
            if (!messageCaseViewRef.current?.validate())
                return;
        }
        else if (messageState.stepWizard === 1) {
            if (!messageNewDocumentViewRef.current?.validate())
                return;
        }

        if (primaryButtonText() === t("Save to 360")) {
            onSaveClick();
        }
        else if (primaryButtonText() === t("Continue")) {
            dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: 1 } });
        }
    }, [dispatch, messageState.stepWizard, onSaveClick, primaryButtonText]);

    const onDefaultClick = useCallback(() => {
        if (defaultButtonText() === t("Cancel")) {
            closePanel();
        }
        else if (defaultButtonText() === t("Go back")) {
            dispatch({ type: MessagesActionType.UpdateStepWizard, payload: { stepWizard: 0 } });
        }
    }, [closePanel, defaultButtonText, dispatch]);

    const onRenderNavigationContent: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <RenderPanelHeader closePanel={closePanel} />
        ), [closePanel]);

    const onRenderFooterContent: IRenderFunction<IPanelProps> = useCallback(
        (props, defaultRender) => (
            <div style={{ display: 'flex', flexDirection: 'row', columnGap: '10px', justifyContent: 'flex-end' }}>
                <DefaultButton text={defaultButtonText()} onClick={onDefaultClick} />
                <PrimaryButton disabled={isSavingInProgress} onClick={onPrimaryClick}
                    text={primaryButtonText()} />
            </div>
        ),
        [defaultButtonText, onDefaultClick, isSavingInProgress, onPrimaryClick, primaryButtonText]
    );

    return <Panel type={PanelType.custom} customWidth="418px" isOpen={isOpen} onDismiss={closePanel} closeButtonAriaLabel={t('Close')}
        styles={{ content: { marginTop: '-10px' } }} isFooterAtBottom={true} onRenderNavigation={onRenderNavigationContent}
        onRenderFooterContent={onRenderFooterContent} isHiddenOnDismiss={false}>
        <StepWizard switchToStep={messageState.stepWizard}>
            <MessageCaseView {...props} ref={messageCaseViewRef} isFileArchiveAction={isOpen} />
            <MessageNewDocumentView {...props} ref={messageNewDocumentViewRef} selectedMessages={selectedMessages} />
        </StepWizard>
    </Panel>;
};

export default ChatArchivePanel;