import React, { useCallback } from 'react';
import { useFiles } from '../../context/FilesContext';
import { Link } from '@fluentui/react';
import { CombineUrl } from '../../helpers/UrlHelper';
import { useP360 } from '../../context/P360Context';
import { useTranslation } from 'react-i18next';

const ChannelAutomaticArchivingInfo: React.FC = () => {
    const { channelRootFolder } = useFiles();
    const { p360State } = useP360();
    const { t } = useTranslation();

    const getChannelInfo = useCallback(() => {
        if (channelRootFolder?.syncConfig == null) {
            return null;
        }

        const style = { marginLeft: 33 };
        const caseLink = <Link target="_blank" href={CombineUrl(p360State.p360Url, channelRootFolder.caseConnection as string)} underline={true}>{channelRootFolder.caseTitle}</Link>;
        let text = channelRootFolder.syncConfig.SyncEnabled === true
            ? t('Automatic synchronization is enabled to case')
            : t('Automatic synchronization is disabled');

        if (channelRootFolder.isReadonly) {
            text = t('Automatic synchronization is disabled because the case is closed');
        }

        return <div style={style}>{text} {caseLink}</div>;
    }, [channelRootFolder?.caseConnection, channelRootFolder?.caseTitle, channelRootFolder?.isReadonly, channelRootFolder?.syncConfig, p360State.p360Url, t]);

    return getChannelInfo();
};

export default ChannelAutomaticArchivingInfo;