import { FC, useEffect, useState } from "react";
import { classNames } from "../../styles/MergeStyleSets";

interface ITranscriptWebVTTDisplayProps {
    data: string;
}

interface IWebVttFormat {
    id: number;
    start: string;
    end: string;
    text: string;
}

const TranscriptWebVTTDisplay: FC<ITranscriptWebVTTDisplayProps> = (props) => {
    const [captions, setCaptions] = useState<IWebVttFormat[]>([]);

    useEffect(() => {
        // Function to parse the WebVTT data
        const parseVTT = (vttText: string) => {
            const lines = vttText.split('\n').filter(line => line.trim() !== '');
            const captions = [];
            let i = 0;
            let caption = { id: i, start: '', end: '', text: '' };

            lines.forEach((line) => {
                // Check for the "WEBVTT" header and skip it
                if (line === 'WEBVTT') return;

                // Match timestamp line
                const regex = RegExp(/(\d{1,2}:\d{2}:\d{2}\.\d{3}) --> (\d{1,2}:\d{2}:\d{2}\.\d{3})/);
                const timestampMatch = regex.exec(line);
                if (timestampMatch) {
                    // If we already have a caption with text, push it to captions list before starting a new one
                    if (caption.start && caption.text.trim()) {
                        captions.push({ ...caption, text: caption.text.trim() });
                    }
                    // Set new timestamps and reset text
                    caption = {
                        id: i,
                        start: timestampMatch[1],
                        end: timestampMatch[2],
                        text: ''
                    };
                } else {
                    // Accumulate text for the current caption
                    caption.text += line + ' ';
                }
                i++;
            });

            // Push the last caption if it has text
            if (caption.start && caption.text.trim()) {
                captions.push({ ...caption, text: caption.text.trim() });
            }

            return captions;
        };

        // Parse the WebVTT data and set it in state
        setCaptions(parseVTT(props.data));
    }, [props.data]);

    return <div>
        <div className={classNames.meetingItemContainerHeader} data-testid="transcriptData"></div>
        <div style={{ paddingLeft: '10px', overflowY: 'scroll' }}>
            {captions.map((caption, index) => (
                <div key={caption.id} style={{ marginBottom: '1em' }}>
                    {/* <strong>{caption.start} -- {caption.end}</strong> */}
                    <p>{caption.text}</p>
                </div>
            ))}
        </div>
    </div>;
};

export default TranscriptWebVTTDisplay;