import React from 'react';
import { IFileListItem } from '../../model/model';
import { Shimmer } from '@fluentui/react';
import { t } from 'i18next';
import TagComponent, { EnumTagComponentType } from '../common/TagComponent';
import { useFiles } from '../../context/FilesContext';

interface SynchronizationFieldProps {
    item: IFileListItem;
    loading: boolean;
}

const SynchronizationField: React.FC<SynchronizationFieldProps> = ({ item, loading }) => {
    const { channelRootFolder } = useFiles();

    if (!item.archivingStatusChecked && loading) {
        return <Shimmer aria-label={t('Loading...')} />;
    }

    const getTagComponentType = (item: IFileListItem) => {
        if (item.isFolder) {
            return getFolderTagComponentType(item);
        }
        
        // file
        if(item.isSyncFailed) {
            return EnumTagComponentType.Conflict;
        }
        
        if (item.syncConfig?.SyncEnabled === true) {
            return item.isReadonly ? EnumTagComponentType.Disabled : EnumTagComponentType.Enabled;
        }

        return EnumTagComponentType.Hidden;
    };

    const getFolderTagComponentType = (item: IFileListItem) => {
        if (item.syncConfig?.InheritFromParent === false) {
            return getTagComponentTypeForNonInheritedFolder(item);
        }
        return getTagComponentTypeForInheritedFolder(item);
    };

    const getTagComponentTypeForNonInheritedFolder = (item: IFileListItem) => {
        if (item.syncConfig?.SyncEnabled === true) {
            return item.isReadonly ? EnumTagComponentType.Disabled : EnumTagComponentType.Automatic;
        }
        return EnumTagComponentType.Manual;
    };

    const getTagComponentTypeForInheritedFolder = (item: IFileListItem) => {
        if (channelRootFolder?.syncConfig?.SyncEnabled === true) {
            if (item.syncConfig && item.syncConfig.InheritFromParent == null) {
                return item.isReadonly ? EnumTagComponentType.Disabled : EnumTagComponentType.Automatic;
            }
            return channelRootFolder.isReadonly === true ? EnumTagComponentType.Disabled : EnumTagComponentType.Automatic;
        }

        // backwards compatibility for old folder bindings
        if (item.caseConnection) {
            return item.isReadonly ? EnumTagComponentType.Disabled : EnumTagComponentType.Manual;
        }

        return EnumTagComponentType.Hidden;
    };

    return (
        <TagComponent type={getTagComponentType(item)} />
    );
};

export default SynchronizationField;