import { FC, useCallback, useState } from "react";
import { Features } from "../../config/Features";
import { classNames } from "../../styles/MergeStyleSets";
import { ICommonProps } from "../ICommonProps";
import { t } from "i18next";
import { Pivot, PivotItem, PrimaryButton } from "@fluentui/react";
import MeetingChat from "./MeetingChat";
import MeetingRecordingList from "./MeetingRecordingList";
import { useBoolean } from "@fluentui/react-hooks";
import { IMessage } from "../../model/model";
import MeetingArchivePanel from "./panel/MeetingArchivePanel";

const MeetingArchiving: FC<ICommonProps> = (props) => {
    const [isArchivePanelOpen, { setTrue: openArchivePanel, setFalse: closeArchivePanel }] = useBoolean(false);
    const [selectedMeetings, setSelectedMeetings] = useState<IMessage[]>([]);

    const onArchiveHandler = useCallback(() => {
        openArchivePanel();
    }, [openArchivePanel]);

    if (Features.EnableMeetingArchiving) {
        return <div>
            <Pivot>
                <PivotItem headerText={t('Archive Chat')}>
                    <MeetingChat {...props} />
                </PivotItem>
                <PivotItem headerText={t('Archive Recording')}>
                    <MeetingRecordingList {...props} setSelectedMeetings={setSelectedMeetings} selectedMeetings={selectedMeetings} />
                </PivotItem>
            </Pivot>
            <PrimaryButton disabled={selectedMeetings.length === 0} style={{ position: 'absolute', top: '10px', right: '10px' }} text={t('Archive')} onClick={onArchiveHandler} />
            <MeetingArchivePanel {...props} isOpen={isArchivePanelOpen} onDismiss={closeArchivePanel} selectedMeetings={selectedMeetings} />
        </div>;
    } else {
        return <blockquote className={classNames.errorBlockQuote}>
            <h3>{t('Meeting Archiving is not supported in this version of 360°')}</h3>
        </blockquote>;
    }
};

export default MeetingArchiving;