import React from 'react';
import { IFileListItem } from '../../model/model';
import { Link, Shimmer } from '@fluentui/react';
import CaseEntityIcon from '../../images/caseentity.svg';
import DocumentEntityIcon from '../../images/documententity.svg';
import { t } from 'i18next';
import { CombineUrl } from '../../helpers/UrlHelper';
import { iconClass } from '../../styles/MergeStyleSets';

interface CaseDocumentFieldProps {
    item: IFileListItem;
    p360url: string;
    loading?: boolean;
}

const CaseDocumentField: React.FC<CaseDocumentFieldProps> = ({ item, p360url, loading }) => {
    if (!item.archivingStatusChecked && loading) {
        return <Shimmer aria-label={t('Loading...')} />;
    }

    if (item.documentConnection) {
        return (
            <span>
                <img src={DocumentEntityIcon} className={iconClass} title={t('Document')} alt={t('Document')} />
                <Link target="_blank" href={CombineUrl(p360url, item.documentConnection)} underline={true} title={`${t('Document')}: ${item.documentTitle}`}>{item.documentTitle}</Link>
            </span>
        );
    }

    if (item.caseConnection) {
        return (
            <span>
                <img src={CaseEntityIcon} className={iconClass} title={t('Case')} alt={t('Case')} />
                <Link target="_blank" href={CombineUrl(p360url, item.caseConnection)} underline={true} title={`${t('Case')}: ${item.caseTitle}`}>{item.caseTitle}</Link>
            </span>
        );
    }

    if (item.isConnectedTo360) {
        return <span style={{ color: 'red' }}>{t('Access Denied')}</span>;
    }
    
    return null;
};

export default CaseDocumentField;