import { FC } from "react";
import { classNames } from "../../styles/MergeStyleSets";
import { IconButton } from "@fluentui/react";
import { t } from "i18next";

interface IRenderPanelHeaderProps {
    closePanel: () => void;
}

export const RenderPanelHeader: FC<IRenderPanelHeaderProps> = (props) => {
    return <div className={classNames.messagePanelHeaderContainer}>
        <div className={classNames.messagePanelHeader}>
            <div className={classNames.messagePanelHeaderIcon}>
                <img src='https://static2.sharepointonline.com/files/fabric/assets/item-types/16/folder.svg' width={50} height={50} alt={t('Folder')} />
            </div>
            <div className={classNames.messagePanelHeaderTextContainer}>
                <div className={classNames.messagePanelHeaderText}>{t('Archive to 360')}</div>
                <div className={classNames.messagePanelHeaderSubText}>{t('Files will be stored on the document card')}</div>
            </div>
            <div className={classNames.messagePanelHeaderCloseIcon}>
                <IconButton iconProps={{ iconName: 'Cancel' }} title={t('Close')} ariaLabel={t('Close')} onClick={props.closePanel} />
            </div>
        </div>
    </div>;
};