import { ConstrainMode, DetailsList, DetailsListLayoutMode, IColumn, IDetailsHeaderProps, IDetailsRowProps, IRenderFunction, ISelection, Selection, SelectionMode, ShimmeredDetailsList, Sticky, StickyPositionType } from "@fluentui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFiles } from "../../context/FilesContext";
import { useP360 } from "../../context/P360Context";
import { IFileListItem } from "../../model/model";
import { classNames } from "../../styles/MergeStyleSets";
import ArchiveStatus from "../archive/ArchiveStatus";
import FileListEmptyMessage from "./FileListEmptyMessage";
import FileNameField from "./FileNameField";
import CaseConnectionLink from "../archive/CaseConnectionLink";
import DocumentConnectionLink from "../archive/DocumentConnectionLink";
import { useApiErrorHandler } from "../../context/ApiErrorHandler";
import { ICommonProps } from "../ICommonProps";
import { Constants } from "../../helpers/Constants";
import DocumentStatusField from "./DocumentStatusField";
import { Features } from "../../config/Features";
import SynchronizationField from "./SynchronizationField";
import CaseDocumentField from "./CaseDocumentField";

interface FileListProps extends ICommonProps {
    setSelectedFiles: (files: IFileListItem[]) => void;
    selectedFiles?: IFileListItem[];
}

const FileList: FC<FileListProps> = (props) => {
    const { navigateToFolder, currentFolder, currentFiles, loadingInfo } = useFiles();
    const { p360State } = useP360();
    const { t } = useTranslation();
    const { error } = useApiErrorHandler();

    const [selection] = useState<ISelection<IFileListItem>>(new Selection<IFileListItem>({
        canSelectItem: (item: IFileListItem): boolean => {
            return !item.isNotSupported &&
                !item.isConnectedTo360 &&
                !!item.modifiedBy &&
                (p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.TruncateLongFileNames) as boolean || item.fileName?.length as number <= Constants.FileNameMaxLengthWithoutBLTruncate) &&
                item.archivingStatusChecked === true;
        },
        onSelectionChanged: () => {
            props.setSelectedFiles(selection.getSelection());
        },
        items: props.selectedFiles ?? [],
        getKey: (item: IFileListItem) => item.fileId as string
    }));

    useEffect(() => {
        if (props.selectedFiles?.length === 0) {
            selection.setAllSelected(false);
        }
    }, [props.selectedFiles, selection]);

    const renderFileTypeIcon = (item: IFileListItem) => {
        return <img src={item.iconName} className={classNames.fileIconImg} alt={`${item.fileType}`} />;
    };

    const oldColumns: IColumn[] = [
        {
            key: 'fileTypeColumn', name: t('File Type'), fieldName: 'fileName', minWidth: 16, maxWidth: 16,
            className: classNames.fileIconCell, iconClassName: classNames.fileIconHeaderIcon, iconName: 'Page', isIconOnly: true, isResizable: true,
            onRender: renderFileTypeIcon
        },
        { key: 'fileNameColumn', name: t('Name'), isMultiline: true, fieldName: 'fileName', minWidth: 120, maxWidth: 400, isResizable: true, className: classNames.textSize },
        { key: 'modified', name: t('Modified'), fieldName: 'modified', minWidth: 80, maxWidth: 180, isResizable: true, className: classNames.textSize },
        { key: 'modifiedBy', name: t('Modified By'), fieldName: 'modifiedBy', minWidth: 100, maxWidth: 200, isResizable: true, className: classNames.textSize },
        { key: 'caseConnectionColumn', name: t('Open case in 360'), isMultiline: true, fieldName: 'caseConnection', minWidth: 100, maxWidth: 400, isResizable: true, className: classNames.textSize },
        { key: 'documentConnectionColumn', name: t('Open document in 360'), isMultiline: true, fieldName: 'documentConnection', minWidth: 100, maxWidth: 500, isResizable: true, className: classNames.textSize },
        { key: 'documentStatusColumn', name: t('Document status'), fieldName: 'documentStatus', minWidth: 120, maxWidth: 180, isResizable: true, className: classNames.textSize },
        { key: 'archiveStatusColumn', name: t('360 Archive status'), fieldName: 'archiveStatus', minWidth: 150, maxWidth: 180, isResizable: true, className: classNames.textSize }
    ];

    const aaEnabledColumns: IColumn[] = [
        {
            key: 'fileTypeColumn', name: t('File Type'), fieldName: 'fileName', minWidth: 16, maxWidth: 16,
            className: classNames.fileIconCell, iconClassName: classNames.fileIconHeaderIcon, iconName: 'Page', isIconOnly: true, isResizable: true,
            onRender: renderFileTypeIcon
        },
        { key: 'fileNameColumn', name: t('Name'), isMultiline: true, fieldName: 'fileName', minWidth: 120, maxWidth: 400, isResizable: true, className: classNames.textSize },
        { key: 'modified', name: t('Modified'), fieldName: 'modified', minWidth: 80, maxWidth: 180, isResizable: true, className: classNames.textSize },
        { key: 'modifiedBy', name: t('Modified By'), fieldName: 'modifiedBy', minWidth: 100, maxWidth: 200, isResizable: true, className: classNames.textSize },
        { key: 'caseDocumentColumn', name: t('Open in 360'), isMultiline: false, fieldName: 'documentConnection', minWidth: 100, maxWidth: 500, isResizable: true, className: classNames.textSize },
        { key: 'syncColumn', name: "Synchronization", fieldName: 'syncConfig', minWidth: 120, maxWidth: 180, isResizable: true, className: classNames.textSize },
        { key: 'documentStatusColumn', name: t('Document status'), fieldName: 'documentStatus', minWidth: 120, maxWidth: 180, isResizable: true, className: classNames.textSize },
        { key: 'archiveStatusColumn', name: t('360 Archive status'), fieldName: 'archiveStatus', minWidth: 150, maxWidth: 180, isResizable: true, className: classNames.textSize }
    ];

    const columns = p360State.backendCapabilities?.Capabilities?.includes(Constants.Capabilities.AutomaticArchiving) ? aaEnabledColumns : oldColumns;

    const clearSelectionAndNavigateToFolder = useCallback((folderId: string) => {
        selection.setAllSelected(false);
        navigateToFolder(folderId);
    }, [navigateToFolder, selection]);

    const renderItemColumn = useCallback((item: IFileListItem, index?: number, column?: IColumn): React.ReactNode => {
        const fieldContent = item[column?.fieldName as keyof IFileListItem] as string;
        switch (column?.key) {
            case 'fileNameColumn':
                return <FileNameField item={item} fieldContent={fieldContent} navigateToFolder={clearSelectionAndNavigateToFolder} />;
            case 'caseConnectionColumn':
                return <CaseConnectionLink item={item} p360url={p360State.p360Url} loading={loadingInfo.currentFolderIsLoading} />;
            case 'documentConnectionColumn':
                return <DocumentConnectionLink item={item} p360url={p360State.p360Url} loading={loadingInfo.currentFolderIsLoading} />;
            case 'archiveStatusColumn':
                return <ArchiveStatus item={item} progress={{ loading: loadingInfo.currentFolderIsLoading }} dataProvider={props.dataProvider} />;
            case 'documentStatusColumn':
                if (Features.UseNewLoading) {
                    return <DocumentStatusField item={item} loading={loadingInfo.currentFolderIsLoading} />;
                }
                return <span>{fieldContent}</span>;
            case 'caseDocumentColumn':
                return <CaseDocumentField item={item} p360url={p360State.p360Url} loading={loadingInfo.currentFolderIsLoading} />;
            case 'syncColumn':
                return <SynchronizationField item={item} loading={loadingInfo.currentFolderIsLoading} />;
            default:
                return <span title={fieldContent}>{fieldContent}</span>;
        }
    }, [clearSelectionAndNavigateToFolder, loadingInfo.currentFolderIsLoading, p360State.p360Url, props.dataProvider]);

    const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = useCallback((headerProps, defaultRender) => {
        if (!headerProps || defaultRender === undefined) { return null; }
        if (!Features.UseNewLoading && loadingInfo.currentFolderIsLoading === true) {
            headerProps.styles = { root: { paddingLeft: '48px' } };
        }
        return (
            <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
                {defaultRender({ ...headerProps })}
            </Sticky>
        );
    }, [loadingInfo.currentFolderIsLoading]);

    const onRenderRow: IRenderFunction<IDetailsRowProps> = useCallback((rowProps, defaultRender) => {
        if (!rowProps || defaultRender === undefined) { return null; }
        if (!Features.UseNewLoading && loadingInfo.currentFolderIsLoading === true) {
            rowProps.styles = { root: { paddingLeft: '48px', minHeight: '44px' } };
        } else {
            rowProps.styles = { root: { minHeight: '44px' } };
        }
        return defaultRender({ ...rowProps });
    }, [loadingInfo.currentFolderIsLoading]);

    if (currentFiles) {
        return <>
            {Features.UseNewLoading ?
                <ShimmeredDetailsList key={currentFolder?.fileId}
                    setKey={currentFolder?.fileId}
                    items={currentFiles ?? []}
                    columns={columns}
                    onRenderItemColumn={renderItemColumn}
                    layoutMode={DetailsListLayoutMode.justified}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    onRenderRow={onRenderRow}
                    selection={selection}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={SelectionMode.multiple}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Select row"
                    constrainMode={ConstrainMode.unconstrained}
                    enableShimmer={!currentFiles.length && loadingInfo.currentFolderIsLoading}
                    ariaLabelForShimmer={t('Loading...')}
                /> :
                <DetailsList key={currentFolder?.fileId}
                    setKey={currentFolder?.fileId}
                    items={currentFiles ?? []}
                    columns={oldColumns}
                    onRenderItemColumn={renderItemColumn}
                    layoutMode={DetailsListLayoutMode.justified}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    onRenderRow={onRenderRow}
                    selection={selection}
                    selectionPreservedOnEmptyClick={true}
                    selectionMode={loadingInfo.currentFolderIsLoading !== true ? SelectionMode.multiple : SelectionMode.none}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="Select row"
                    constrainMode={ConstrainMode.unconstrained}
                />}
            {currentFiles.length === 0 && !loadingInfo.currentFolderIsLoading && !error && <FileListEmptyMessage />}
        </>;
    }
    return null;
};

export default FileList;