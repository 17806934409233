import { t } from "i18next";
import { FC } from "react";

export enum EnumTagComponentType {
    Disabled,
    Enabled,
    Automatic,
    Manual,
    Conflict,
    Hidden
}

export interface ITagComponentProps {
    type: EnumTagComponentType;
    title?: string;
}

const TagComponent: FC<ITagComponentProps> = ({type, title}) => {
    if (type === EnumTagComponentType.Hidden) {
        return null;
    }

    let text = '';

    const tagStyles = {
        backgroundColor: '#efefef',
        color: '#000000',
        padding: '1px 15px 4px 15px',
        borderRadius: '10px',
        fontSize: '12px',
        display: 'inline-block',
    };
    switch (type) {
        case EnumTagComponentType.Conflict:
        case EnumTagComponentType.Disabled:
            text = t('Disabled');
            tagStyles.backgroundColor = '#efefef';
            tagStyles.color = '#999999';
            break;
        case EnumTagComponentType.Enabled:
            text = t('Enabled');
            break;
        case EnumTagComponentType.Automatic:
            text = t('Automatic');
            break;
        case EnumTagComponentType.Manual:
            text = t('Manual');
            break;
    }

    return text ? <span title={title ?? text} style={tagStyles}>{text}</span> : null;
};

export default TagComponent;